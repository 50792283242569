import style from "./VerRemisiones.module.css";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import DivRemisionParts from "../Remisiones/RemisionesParts";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllRemision,
  getAllClients,
  getAllUsers,
} from "../../redux/actions";
import Spinner from "../../components/Spinner/Spinner";

const VerRemisiones = () => {
  const [detalle, setDetalle] = useState(false);
  const [detalleRem, setDetalleRem] = useState({});
  const [filtroState, setFiltroState] = useState(false);
  const [filtroQuote, setFiltroQuote] = useState(false);
  const [edit, setEdit] = useState(false);
  const [remisionParts, setRemisionParts] = useState([]);
  const [editRem, setEditRem] = useState({});
  const [editRemCopy, setEditRemCopy] = useState({});
  const [matchedUser, setMatchedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [clientTerm, setClientTerm] = useState();
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchByClient, setSearchByClient] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isDropdownVisibleClients, setIsDropdownVisibleClients] =
    useState(false);
  const [byNameFilter, setByNameFilter] = useState(false);
  const [byClientFilter, setByClientFilter] = useState(false);
  const [filteredClients, setFilteredClients] = useState([]);
  const [filteredClient, setFilteredClient] = useState([]);
  const [remisionToDisplay, setRemisionToDisplay] = useState([]);
  const [confirmChangeQuote, setConfirmChangeQuote] = useState(false);
  const [changeId, setChangeId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [paginatedRem, setPaginatedRem] = useState([]);
  const [confirmMessage, setConfirmMessage] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  const dispatch = useDispatch();
  const remisions = useSelector((state) => state.remisions);
  const clients = useSelector((state) => state.clients);
  const prods = useSelector((state) => state.inventario);
  const users = useSelector((state) => state.users);

  useEffect(() => {
    if (byNameFilter) {
      setRemisionToDisplay(filteredClients);
    } else if (byClientFilter) {
      setRemisionToDisplay(filteredClient);
    } else {
      const sortedPaginatedRem = [...paginatedRem].sort((a, b) => a.id - b.id);
      setRemisionToDisplay(sortedPaginatedRem);
    }
  }, [
    byNameFilter,
    filteredClients,
    remisions,
    filteredClient,
    byClientFilter,
    paginatedRem,
    remisions,
  ]);

  useEffect(() => {
    dispatch(getAllRemision());
    dispatch(getAllClients());
    dispatch(getAllUsers());
  }, []);

  const handleAddRemisionPart = (part) => {
    setRemisionParts([...remisionParts, part]);
  };

  const handleStateChange = (event) => {
    const newState = event.target.value === "true";
    setEditRem((prevEditRem) => ({
      ...prevEditRem,
      state: newState,
      quoteState: !newState,
    }));
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    dispatch(getAllUsers());
    if (value.length > 0) {
      const filtered = users.filter((user) => {
        const name =
          typeof user.name === "string" ? user.name.toLowerCase() : "";
        const surname =
          typeof user.surname === "string" ? user.surname.toLowerCase() : "";
        return (
          name.includes(value.toLowerCase()) ||
          surname.includes(value.toLowerCase())
        );
      });
      setFilteredUsers(filtered);
      setIsDropdownVisible(true);
    } else {
      setIsDropdownVisible(false);
      setByNameFilter(false);
    }
  };

  const handleClientSearch = (e) => {
    const value = e.target.value;
    setClientTerm(value);

    if (value.length > 0) {
      const filtered = clients.filter(
        (client) =>
          client.cliente.toLowerCase().includes(value.toLowerCase()) ||
          client.identificacion.toLowerCase().includes(value.toLowerCase())
      );
      setSearchByClient(filtered);
      setIsDropdownVisibleClients(true);
    } else {
      setIsDropdownVisibleClients(false);
      setByClientFilter(false);
      setByNameFilter(false);
    }
  };

  const selectUser = (userId, name) => {
    setIsDropdownVisible(false);
    setIsDropdownVisibleClients(false);
    setByNameFilter(true);
    setSearchTerm(name);
    setClientTerm("");
    setByClientFilter(false);

    const filteredRemisions = remisions
      .filter((remision) => remision.userId === userId)
      .sort((a, b) => a.id - b.id);

    if (filteredRemisions.length < 1) {
      setFilteredClients(null);
    } else {
      setFilteredClients(filteredRemisions);
    }
  };

  const selectClient = (identificacion, name) => {
    setIsDropdownVisibleClients(false);
    setIsDropdownVisible(false);
    setByClientFilter(true);
    setClientTerm(name);
    setSearchTerm("");
    setByNameFilter(false);

    const filteredClients = remisions
      .filter((cliente) => cliente.cliente === name)
      .sort((a, b) => a.id - b.id);

    if (filteredClients.length < 1) {
      setFilteredClient(null);
    } else {
      setFilteredClient(filteredClients);
    }
  };

  const handleUpdateRemision = async (oldData) => {
    // Proceso de actualización de las partes de la remisión como antes
    const remisionPartsUpdated = remisionParts.map((part) => {
      const precioVenta = Number(part.precioVenta);
      const quantity = Number(part.quantity);
      const precioTotal = precioVenta * quantity;
      return {
        ...part,
        precioTotal: precioTotal,
      };
    });

    // Cálculo de totales y otros valores como antes
    let calculatedTotal = 0;
    remisionPartsUpdated.forEach((part) => {
      calculatedTotal += part.precioTotal;
    });

    const totalIncludingTax = calculatedTotal;
    const calcSubTot = totalIncludingTax / 1.19;

    let definitiveTotal = 0;
    let newRetencion = 0;

    if (editRem.retencion) {
      newRetencion = calcSubTot * 0.025;
      definitiveTotal = calculatedTotal - newRetencion;
    } else {
      definitiveTotal = calculatedTotal;
    }

    // Preparación de newData con las partes actualizadas y otros datos relevantes
    const newData = {
      clientId: editRem.clientId,
      date: editRem.date,
      cliente: editRem.cliente,
      observations: editRem.observations,
      remisionParts: remisionPartsUpdated,
      iva: (totalIncludingTax - calcSubTot).toFixed(2),
      total: definitiveTotal.toFixed(2),
      subtotal: calcSubTot.toFixed(2),
      userId: editRem.userId,
      retencion: editRem.retencion,
      valorRetencion: newRetencion.toFixed(2),
    };

    // Preparación del oldData para enviar solo las partes relevantes de la remisión
    // Aquí asumimos que oldData es un objeto que contiene las remisionParts y posiblemente otros campos que ya no son necesarios enviar.
    const oldDataToSend = {
      remisionParts: oldData.remisionParts, // Asegúrate de que esto extrae solo las partes de la remisión de oldData
    };

    // Construcción del cuerpo de la solicitud para cumplir con los nuevos requisitos de la API
    const requestBody = {
      newData: newData,
      oldData: oldDataToSend,
    };

    try {
      // Petición a la API para actualizar la remisión con los nuevos datos
      const response = await axios.patch(
        `https://api.devlasbalineras.com/remisions/update/${editRem.id}`,
        requestBody
      );
      alert("Remisión actualizada con éxito");
      setEdit(false);
      dispatch(getAllRemision());
    } catch (error) {
      console.error("Error al actualizar la remisión:", error);
      alert("Error al actualizar la remisión");
    }
  };

  const handleGeneratePDF = async (data) => {
    const getClientName = (clients, clientId) => {
      const client = clients.find((client) => client.id === clientId);
      return client ? client : "Cliente desconocido";
    };

    const clientData = getClientName(clients, data.clientId);

    const user = users.find((user) => user.id === data.userId);
    if (user) {
      data.userName = user.name;
    } else {
      console.error("User not found");
      return;
    }

    let calculatedTotal = 0;
    data.remisionParts.forEach((part) => {
      calculatedTotal += part.precioTotal;
    });

    const calculatedIva = calculatedTotal - data.subtotal;

    data.tipoIdentificacion = clientData.tipoIdentificacion;
    data.identificacion = clientData.identificacion;
    data.direccion = clientData.direccion;
    data.telefono = clientData.telefono;
    data.iva = calculatedIva.toFixed(2);
    data.total = calculatedTotal;

    if (data.retencion) {
      data.total = calculatedTotal - data.valorRetencion;
    }

    try {
      const apiKey =
        "oxVO5arC84guGAd3qMmxJSmC6jeIpYLk05j3kVX3N7hKbAv78GE4RYfJL4U4j0CF";
      const response = await fetch("https://api.prexview.com/v1/transform", {
        method: "POST",
        headers: {
          Authorization: apiKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          json: JSON.stringify(data),
          template: "lasbalineras_rem",
          output: "pdf",
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `Remision #${data.id}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const showDetail = (id, userId) => {
    const remision = remisions.find((c) => c.id === id);

    if (remision) {
      setDetalleRem(remision);
      setDetalle(true);
      const user = users.find((user) => user.id === userId);
      if (user) {
        setMatchedUser(user);
      }
    } else {
      alert("no se encontró la remision");
    }
  };

  const updateRemisionPart = (index, field, value) => {
    const updatedParts = [...remisionParts];
    updatedParts[index] = { ...updatedParts[index], [field]: value };
    setRemisionParts(updatedParts);
  };

  const deleteRemisionPart = (index) => {
    const updatedParts = [...remisionParts];
    updatedParts.splice(index, 1);
    setRemisionParts(updatedParts);
  };

  const showEdit = (id) => {
    const remision = remisions.find((c) => c.id === id);
    if (remision) {
      setEditRem(remision);
      setRemisionParts(remision.remisionParts || []);
      setEditRemCopy(remision);
      setEdit(true);
    } else {
      alert("No se encontró la remisión");
    }
  };

  const filterByState = () => {
    setFiltroState(!filtroState);
    setFiltroQuote(false);
  };

  const filterByQuote = () => {
    setFiltroQuote(!filtroQuote);
    setFiltroState(false);
  };

  const handleQuoteAndState = async (id) => {
    try {
      const response = await axios.patch(
        `https://api.devlasbalineras.com/remisions/quote-state/${id}`
      );

      if (response.data.success === true) {
        const peticion = await axios.patch(
          `https://api.devlasbalineras.com/remisions/state/${id}`
        );

        if (peticion.data.success === true) {
          alert("Cotizado correctamente");
          dispatch(getAllRemision());
          setConfirmChangeQuote(false);
        } else {
          alert("No se pudo cambair el state");
        }
      } else {
        alert("No se pudo cambiar el quote");
      }
    } catch (error) {
      alert(error);
    }
  };

  const fetchClients = async (page) => {
    try {
      const response = await axios.get(
        `https://api.devlasbalineras.com/remisions/all/paginate/?page=${page}&limit=10`
      );
      if (response.data.success) {
        setPaginatedRem(response.data.data.data);
        setTotalPages(response.data.data.totalPages);
      } else {
        alert("No se pudieron cargar los productos");
      }
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    fetchClients(currentPage);
  }, [currentPage, remisions]);

  const handleFirstPage = () => setCurrentPage(1);
  const handlePrevPage = () =>
    setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
  const handleNextPage = () =>
    setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));
  const handleLastPage = () => setCurrentPage(totalPages);

  const deleteRemision = async (id) => {
    try {
      const response = await axios.delete(
        `https://api.devlasbalineras.com/remisions/delete/${id}`
      );

      if (response.data.success === true) {
        dispatch(getAllRemision());
        alert("Eliminado correctamente");
      } else {
        alert("error con success");
      }
    } catch (error) {
      alert(error);
    }
  };

  const handleDelete = (id) => {
    setConfirmMessage(true);
    setIdToDelete(id);
  };

  return (
    <div className={style.divContenedorRemisiones}>
      {remisions != undefined ? (
        <>
          <div className={style.divHeaderRemisiones}>
            {confirmChangeQuote ? (
              <div className={style.divConfirmChange}>
                <h2>Cambiar a cotizacion</h2>
                <h4>! No se podra cambiar a su estado original</h4>
                <div>
                  <button onClick={() => handleQuoteAndState(changeId)}>
                    Confirmar
                  </button>
                  <button onClick={() => setConfirmChangeQuote(false)}>
                    Cancelar
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
            <h1>Remisiones</h1>
            <button
              onClick={filterByState}
              className={
                filtroState
                  ? `${style.active} ${style.filterButton}`
                  : style.filterButton
              }
            >
              Activo
            </button>
            <button
              onClick={filterByQuote}
              className={
                filtroQuote
                  ? `${style.active} ${style.filterButton}`
                  : style.filterButton
              }
            >
              En Cotización
            </button>
            <div className={style.divInputSearch}>
              <input
                type="text"
                placeholder="Remisiones por usuario..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
              {isDropdownVisible && filteredUsers.length > 0 && (
                <div className={style.dropdown}>
                  {filteredUsers.map((user) => (
                    <div
                      key={user.id}
                      className={style.dropdownItem}
                      onClick={() => selectUser(user.id, user.name)}
                    >
                      {user.name} {user.surname}
                    </div>
                  ))}
                </div>
              )}
              <input
                type="text"
                placeholder="Remisiones por cliente..."
                value={clientTerm}
                onChange={handleClientSearch}
              />
              {isDropdownVisibleClients && searchByClient.length > 0 && (
                <div className={style.dropdown}>
                  {searchByClient.map((cliente) => (
                    <div
                      key={cliente.id}
                      className={style.dropdownItem}
                      onClick={() =>
                        selectClient(cliente.identificacion, cliente.cliente)
                      }
                    >
                      {cliente.cliente}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className={style.pagination}>
              <button onClick={handleFirstPage}>Primer Página</button>
              <button onClick={handlePrevPage}>Anterior</button>
              <span>
                Página {currentPage} de {totalPages}
              </span>
              <button onClick={handleNextPage}>Siguiente</button>
              <button onClick={handleLastPage}>Última Página</button>
            </div>
          </div>
          <div className={style.divRemisiones}>
            <div className={style.referenciasDiv}>
              <h4>ID</h4>
              <h4>Cliente ( id )</h4>
              <h4>Fecha</h4>
              <h4>Total</h4>
              <h4>Activo</h4>
              <h4>En Cotización</h4>
              <h4>Acciones</h4>
            </div>
            <div className={style.remisiones}>
              {remisionToDisplay === null ? (
                <h1>No hay remisiones</h1>
              ) : remisionToDisplay.length > 0 ? (
                remisionToDisplay
                  .filter(
                    (remision) =>
                      (!filtroState || remision.state) &&
                      (!filtroQuote || remision.quoteState)
                  )
                  .map((remision) => (
                    <div key={remision.id} className={style.remision}>
                      <p>{remision.id}</p>
                      <p>{`${remision.cliente} (${remision.clientId})`}</p>
                      <p>{remision.date}</p>
                      <p>$ {Number(remision.total).toLocaleString()}</p>
                      <p>{remision.state ? "Activo" : "Inactivo"}</p>
                      <p>{remision.quoteState ? "Sí" : "No"}</p>
                      <div className={style.divAcciones}>
                        <div
                          onClick={() => showEdit(remision.id)}
                          className={style.divAction}
                        >
                          <ion-icon
                            name="create-outline"
                            title="Editar"
                          ></ion-icon>
                        </div>
                        <div
                          onClick={() =>
                            showDetail(remision.id, remision.userId)
                          }
                          className={style.divAction}
                        >
                          <ion-icon
                            name="receipt-outline"
                            title="Detalle"
                          ></ion-icon>
                        </div>
                        <div onClick={() => handleGeneratePDF(remision)}>
                          <ion-icon
                            name="download-outline"
                            title="Descargar"
                          ></ion-icon>
                        </div>
                        {!remision.quoteState && (
                          <div
                            onClick={() => {
                              setConfirmChangeQuote(true);
                              setChangeId(remision.id);
                            }}
                          >
                            <ion-icon
                              name="pricetags-outline"
                              title="Cotizar"
                            ></ion-icon>
                          </div>
                        )}
                        <div onClick={() => handleDelete(remision.id)}>
                          <ion-icon
                            name="trash-outline"
                            title="Eliminar"
                          ></ion-icon>
                        </div>
                      </div>
                    </div>
                  ))
              ) : (
                <Spinner />
              )}
              {confirmMessage ? (
                <div className={style.modalBackground}>
                  <div className={style.modalContainer}>
                    <h6>Desea eliminar la remision ?</h6>
                    <button onClick={() => deleteRemision(idToDelete)}>
                      Confirmar
                    </button>
                    <button onClick={() => setConfirmMessage(false)}>
                      Cancelar
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div
            className={
              detalle ? style.detalleRemisionDiv : style.detalleRemisionOffDiv
            }
          >
            {detalle ? (
              <div className={style.detalleRemision}>
                <h1>Detalle de la remision</h1>
                <div
                  className={style.closeButton}
                  onClick={() => setDetalle(false)}
                >
                  <ion-icon name="close-outline"></ion-icon>
                </div>
                <div className={style.detalleClient}>
                  <h3>Cliente :</h3>
                  <h4>{detalleRem.cliente !== "" ? detalleRem.cliente : ""}</h4>
                </div>
                <div className={style.detalleDate}>
                  <h3>Fecha :</h3>
                  <DatePicker
                    selected={detalleRem.date}
                    dateFormat="dd-MM-yyyy"
                    disabled
                  />
                </div>
                <div className={style.detalleObservaciones}>
                  <h3>Observaciones :</h3>
                  <h4>
                    {detalleRem.observations !== ""
                      ? detalleRem.observations
                      : ""}
                  </h4>
                </div>
                <div className={style.detalleObservaciones}>
                  <h3>Contacto :</h3>
                  <h4>{detalleRem.contact !== "" ? detalleRem.contact : ""}</h4>
                </div>
                <div className={style.detalleObservaciones}>
                  <h3>Creado Por :</h3>
                  <h4>
                    {matchedUser !== null
                      ? `${matchedUser.name} ${matchedUser.surname}`
                      : ""}
                  </h4>
                </div>
                <div className={style.detalleObservaciones}>
                  <h3>Numero :</h3>
                  <h4>
                    {detalleRem.contactPhone !== ""
                      ? detalleRem.contactPhone
                      : ""}
                  </h4>
                </div>
                <div className={style.detalleProductos}>
                  <h3>Productos :</h3>
                  <div className={style.contenedorProductosDetalle}>
                    {detalleRem.remisionParts.length > 0
                      ? detalleRem.remisionParts.map((p) => {
                          return (
                            <div className={style.productoDetalle}>
                              <h5>
                                {p.descripcion} ({p.item})
                              </h5>
                              <h5>{p.quantity} Unidades</h5>
                              <h5>$ {p.precioVenta}</h5>
                              {p.quantity > p.cantidadDisponible ? (
                                <ion-icon
                                  name="alert-circle-outline"
                                  title="Supera la cantidad disponible"
                                ></ion-icon>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>
                <div className={style.detalleObservaciones}>
                  <h3>Total :</h3>
                  <h4>$ {Number(detalleRem.total).toLocaleString()}</h4>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className={edit ? style.editRemisionDiv : style.editRemisionDivOff}
          >
            {edit && (
              <form
                onSubmit={(e) => e.preventDefault()}
                className={style.editForm}
              >
                <div
                  className={style.buttonCloseEdit}
                  onClick={() => setEdit(false)}
                >
                  <ion-icon name="close-outline"></ion-icon>
                </div>

                <div className={style.formGroup}>
                  <label htmlFor="cliente">Cliente</label>
                  <input
                    type="text"
                    id="cliente"
                    value={editRem.cliente || ""}
                    onChange={(e) =>
                      setEditRem({ ...editRem, cliente: e.target.value })
                    }
                    disabled
                  />
                </div>
                <div className={style.formGroup}>
                  <label htmlFor="date">Fecha</label>
                  <DatePicker
                    selected={new Date(editRem.date)}
                    onChange={(date) => setEditRem({ ...editRem, date })}
                    dateFormat="yyyy-MM-dd"
                  />
                </div>
                <div className={style.formGroup}>
                  <label htmlFor="observations">Observaciones</label>
                  <input
                    type="text"
                    id="observations"
                    value={editRem.observations || ""}
                    onChange={(e) =>
                      setEditRem({ ...editRem, observations: e.target.value })
                    }
                  />
                </div>

                <DivRemisionParts
                  onAddRemisionPart={handleAddRemisionPart}
                  productos={prods}
                />

                <div className={style.remisionPartsList}>
                  {remisionParts.map((part, index) => (
                    <div key={index} className={style.remisionPart}>
                      <p>
                        {part.descripcion} ({part.item})
                      </p>
                      <p>
                        Cantidad:
                        <input
                          type="number"
                          value={part.quantity}
                          onChange={(e) =>
                            updateRemisionPart(
                              index,
                              "quantity",
                              e.target.value
                            )
                          }
                        />
                      </p>
                      <p>
                        Precio:
                        <input
                          type="number"
                          value={part.precioVenta}
                          onChange={(e) =>
                            updateRemisionPart(
                              index,
                              "precioVenta",
                              e.target.value
                            )
                          }
                        />
                      </p>
                      <div onClick={() => deleteRemisionPart(index)}>
                        <ion-icon name="trash-outline"></ion-icon>
                      </div>
                    </div>
                  ))}
                </div>

                <button
                  type="button"
                  onClick={() => handleUpdateRemision(editRemCopy)}
                  className={style.saveButton}
                >
                  Guardar Cambios
                </button>
              </form>
            )}
          </div>
        </>
      ) : (
        <h1>No hay remisiones...</h1>
      )}
    </div>
  );
};

export default VerRemisiones;
