import React, { useState, useEffect } from "react";
import axios from "axios";
import style from "./App.module.css";

const SubcategorySelector = ({ categoryId, onSubcategorySelect }) => {
  const [subcategories, setSubcategories] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [newSubcategoryName, setNewSubcategoryName] = useState("");
  const [isCreatingSubcategory, setIsCreatingSubcategory] = useState(false);

  useEffect(() => {
    setSearchTerm("");
    if (categoryId) {
      axios
        .get("https://application.devlasbalineras.com/subcategories/all")
        .then((response) => {
          if (response.data.success) {
            const filtered = response.data.elements.filter(
              (subcategory) =>
                subcategory.categorieId === categoryId &&
                !(categoryId === 3 && subcategory.id === 10)
            );
            setSubcategories(filtered);
            setFilteredSubcategories(filtered);
          }
        })
        .catch((error) => {
          console.error(
            "There was an error fetching the subcategories!",
            error
          );
        });
    }
  }, [categoryId]);

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    setIsDropdownVisible(true);

    if (term === "") {
      setFilteredSubcategories(subcategories);
    } else {
      const filtered = subcategories.filter((subcategory) =>
        subcategory.name.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredSubcategories(filtered);
    }
  };

  const handleSelect = (subcategory) => {
    setSearchTerm(subcategory.name);
    setFilteredSubcategories([subcategory]);
    setIsDropdownVisible(false);
    onSubcategorySelect(subcategory.id);
  };

  const handleInputClick = () => {
    setIsDropdownVisible(!isDropdownVisible);
    setFilteredSubcategories(subcategories);
  };

  const handleClearInput = () => {
    setSearchTerm("");
    setFilteredSubcategories(subcategories);
    setIsDropdownVisible(false);
    onSubcategorySelect(null);
  };

  const handleCreateSubcategory = () => {
    if (newSubcategoryName.trim() === "") return;

    const body = {
      categorieId: categoryId,
      name: newSubcategoryName,
      state: true,
    };

    axios
      .post(
        "https://application.devlasbalineras.com/subcategories/create",
        body
      )
      .then((response) => {
        alert("Subcategoría creada exitosamente");
        setNewSubcategoryName("");
        setIsCreatingSubcategory(false);
        // Refresh subcategories list
        return axios.get(
          "https://application.devlasbalineras.com/subcategories/all"
        );
      })
      .then((response) => {
        if (response.data.success) {
          const filtered = response.data.elements.filter(
            (subcategory) =>
              subcategory.categorieId === categoryId &&
              !(categoryId === 3 && subcategory.id === 10)
          );
          setSubcategories(filtered);
          setFilteredSubcategories(filtered);
        }
      })
      .catch((error) => {
        console.error("There was an error creating the subcategory!", error);
      });
  };

  return (
    <div className={style.containerInput}>
      <label>Subcategoria</label>
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        onClick={handleInputClick}
        placeholder="Buscar subcategorías..."
      />
      {isDropdownVisible && (
        <div className={style.dropdownDivCats}>
          {filteredSubcategories.map((subcategory) => (
            <button
              key={subcategory.id}
              onClick={() => handleSelect(subcategory)}
            >
              {subcategory.name}
            </button>
          ))}
          {filteredSubcategories.length === 0 && (
            <button
              onClick={() => setIsCreatingSubcategory(true)}
              className={style.createOptionButton}
            >
              Crear nueva subcategoría
            </button>
          )}
        </div>
      )}
      <button onClick={handleClearInput} className={style.clearButtonCats}>
        <ion-icon name="close-circle-outline"></ion-icon>
      </button>
      {isCreatingSubcategory && (
        <div className={style.divContCrearOpcion}>
          <i onClick={() => setIsCreatingSubcategory(false)}>X</i>
          <h3>Creador de subcategorías</h3>
          <div>
            <label>Nombre de la subcategoría</label>
            <input
              type="text"
              placeholder="Nombre"
              value={newSubcategoryName}
              onChange={(e) => setNewSubcategoryName(e.target.value)}
            />
          </div>
          <button onClick={handleCreateSubcategory}>Crear Subcategoría</button>
        </div>
      )}
    </div>
  );
};

export default SubcategorySelector;
