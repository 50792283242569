import style from "./Login.module.css";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getAllUsers } from "../../redux/actions";
import Spinner from "../../components/Spinner/Spinner";
import logo from "../../assets/logo_balineras.png";
import axios from "axios";

const Login = () => {
  const [optionSelected, setOptionSelected] = useState(true);
  const [seePass, setSeePass] = useState(false);
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(null);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    email: false,
    name: false,
    password: false,
    surname: false,
  });
  const [loading, setLoading] = useState(false);
  const [badLogin, setBadLogin] = useState(false);
  const [userFound, setUserFound] = useState(null);
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const handleButtonClick = (i) => {
    setSeePass(false);
    setOptionSelected(i);
  };

  const handleInputEmail = (event) => {
    const newValue = event.target.value;
    if (newValue === "") {
      setEmail(newValue);
      setErrors({ ...errors, email: true });
    } else {
      setEmail(newValue);
      setErrors({ ...errors, email: false });
    }
  };

  const handleInputName = (event) => {
    const newValue = event.target.value;
    if (newValue === "") {
      setName(newValue);
      setErrors({ ...errors, name: true });
    } else {
      setName(newValue);
      setErrors({ ...errors, name: false });
    }
  };

  const handleInputSurname = (event) => {
    const newValue = event.target.value;
    if (newValue === "") {
      setSurname(newValue);
      setErrors({ ...errors, surname: true });
    } else {
      setSurname(newValue);
      setErrors({ ...errors, surname: false });
    }
  };

  const handleInputPassword = (event) => {
    const newValue = event.target.value;
    if (newValue === "") {
      setPassword(newValue);
      setErrors({ ...errors, password: true });
    } else {
      setPassword(newValue);
      setErrors({ ...errors, password: false });
    }
  };

  const checkEmail = () => {
    const user = users.find((user) => user.email === email);

    if (user) {
      setUserFound(user);
      setSuccess(true);
    } else {
      setUserFound(null);
      setSuccess(false);
      setErrors({
        ...errors,
        email: true,
      });
    }
  };

  const checkCredentials = async () => {
    if (name != "" && surname != "" && password != "") {
      const data = { name: name, surname: surname, password: password };
      const createUser = await axios.patch(
        `https://api.devlasbalineras.com/users/${userFound.id}`,
        data
      );

      if (createUser.data.success === true) {
        alert("Usuario creado correctamente");
        localStorage.setItem("idUser", createUser.data.user.id);
        window.location.href = "/home";
      } else {
        alert("no se pudo crear el usuario");
      }
    } else {
      setErrors({
        name: true,
        password: true,
      });
    }
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post(
        "https://api.devlasbalineras.com/users/login",
        {
          email,
          password,
        }
      );
      setLoading(true);

      if (response.data.success === true) {
        localStorage.setItem("idUser", response.data.user.id);
        setLoading(false);
        window.location.href = "/home";
      } else {
        setBadLogin(true);
        setLoading(false);
        setEmail("");
        setPassword("");
      }
    } catch (error) {
      setLoading(false);
      setBadLogin(true);
    }
  };

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  return (
    <>
      <div className={style.divContainerLogin}>
        {loading ? (
          <div className={style.divContenedorSpinnerLog}>
            <Spinner />
          </div>
        ) : (
          ""
        )}
        {badLogin ? (
          <div className={style.divBadLogin}>
            <h1>No se encontraron las credenciales</h1>
            <ion-icon name="alert-circle-outline"></ion-icon>
            <button onClick={() => setBadLogin(false)}>Cerrar</button>
          </div>
        ) : (
          ""
        )}
        <div className={loading ? style.divLoginLoading : style.divLogin}>
          <img src={logo} alt="logo" />
          <div className={style.options}>
            <button
              className={
                optionSelected ? style.activeButton : style.inactiveButton
              }
              onClick={() => handleButtonClick(true)}
              disabled={success}
            >
              Login
            </button>
            <h1>/</h1>
            <button
              className={
                !optionSelected ? style.activeButton : style.inactiveButton
              }
              onClick={() => handleButtonClick(false)}
            >
              Registro
            </button>
          </div>
          {optionSelected ? (
            <>
              <div className={style.divInputs}>
                <input
                  type="text"
                  placeholder="Email..."
                  value={email}
                  onChange={handleInputEmail}
                />
                {errors.email && (
                  <span className={style.errorText}>Email is required</span>
                )}
                <div className={style.divPassword}>
                  <input
                    type={seePass ? "text" : "password"}
                    placeholder="Contraseña..."
                    value={password}
                    onChange={handleInputPassword}
                  />
                  <div onClick={() => setSeePass(!seePass)}>
                    <ion-icon name="eye-outline"></ion-icon>
                  </div>
                </div>
                {errors.password && (
                  <span className={style.errorText}>Password is required</span>
                )}
              </div>
              <button
                className={style.buttonLogin}
                disabled={email === "" || password === ""}
                onClick={() => handleLogin()}
              >
                Iniciar Sesion
              </button>
              <Link to="/changePass" target="_blank">
                ¿ Olvidaste tu contraseña ?
              </Link>
            </>
          ) : (
            <>
              <div className={style.divInputs}>
                {success === null ? (
                  <div className={style.divContenedorCheckEmail}>
                    <input
                      type="text"
                      placeholder="Email..."
                      value={email}
                      onChange={handleInputEmail}
                    />
                    {errors.email && (
                      <span className={style.errorText}>Email is required</span>
                    )}
                  </div>
                ) : success === true ? (
                  <>
                    <input
                      type="text"
                      placeholder="Nombre. . ."
                      value={name}
                      onChange={handleInputName}
                    />
                    {errors.name && (
                      <span className={style.errorText}>Name is required</span>
                    )}
                    <input
                      type="text"
                      placeholder="Apellido. . ."
                      value={surname}
                      onChange={handleInputSurname}
                    />
                    {errors.surname && (
                      <span className={style.errorText}>
                        Surname is required
                      </span>
                    )}
                    <div className={style.divPassword}>
                      <input
                        type={seePass ? "text" : "password"}
                        placeholder="Contraseña..."
                        value={password}
                        onChange={handleInputPassword}
                      />
                      <div onClick={() => setSeePass(!seePass)}>
                        <ion-icon name="eye-outline"></ion-icon>
                      </div>
                    </div>
                    {errors.password && (
                      <span className={style.errorText}>
                        Password is required
                      </span>
                    )}
                  </>
                ) : (
                  <div className={style.divErrorEmail}>
                    <h3>
                      <ion-icon name="alert-circle-outline"></ion-icon> No
                      encontramos ese correo en los autorizados
                    </h3>
                    <button
                      className={style.buttonBack}
                      onClick={() => setSuccess(null)}
                    >
                      Volver
                    </button>
                  </div>
                )}
              </div>
              <button
                className={style.buttonLogin}
                onClick={
                  success === null
                    ? checkEmail
                    : success === true
                    ? checkCredentials
                    : ""
                }
                disabled={
                  success === null
                    ? errors.email
                    : success === true
                    ? errors.name || errors.password
                    : ""
                }
              >
                Crear Cuenta
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Login;

//import logo from "../../assets/logo_balineras.png";
