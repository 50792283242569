import { GET_ALL_PRODUCTS } from "../actionTypes";
import axios from "axios";

export const getAllProductsAction = () => {
  return async function (dispatch) {
    try {
      const apiData = await axios.get("https://api.devlasbalineras.com/inventario");
      const data = apiData.data.data;

      dispatch({ type: GET_ALL_PRODUCTS, payload: data });
    } catch (error) {
      return error.message;
    }
  };
};
