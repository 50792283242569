import React, { useState, useEffect } from "react";
import style from "./Remisiones.module.css";
import axios from "axios";
import { getAllProducts } from "../../redux/actions";
import { useDispatch } from "react-redux";

const DivRemisionParts = ({ onAddRemisionPart, productos }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProduct, setSelectedProduct] = useState({});
  const [quantity, setQuantity] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [precioVenta, setPrecioVenta] = useState("");
  const dispatch = useDispatch();

  const handleSearchTermChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term.length >= 3) {
      const filtered = productos.filter(
        (product) =>
          product.descripcion.toLowerCase().includes(term.toLowerCase()) ||
          product.item.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
  };

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
    setSearchTerm(`${product.descripcion} (${product.item})`);
    setPrecioVenta(product.precioVenta);
    setFilteredProducts([]);
  };

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const handleAddRemisionPart = async () => {
    if (selectedProduct && quantity && precioVenta) {
      const remisionPart = {
        id: selectedProduct.itemId,
        item: selectedProduct.item,
        descripcion: selectedProduct.descripcion,
        precioVenta: precioVenta,
        quantity: Number(quantity),
        precioTotal: precioVenta * quantity,
        cantidadDisponible: selectedProduct.cantidadDisponible,
      };

      try {
        onAddRemisionPart(remisionPart);
        setSelectedProduct({});
        setSearchTerm("");
        setQuantity("");
        setPrecioVenta("");
      } catch (error) {
        console.error("Error al actualizar el producto:", error);
      }
    }
  };

  return (
    <div className={style.divRemisionParts}>
      <div className={style.DivSearchProduct}>
        <input
          type="text"
          placeholder="Buscar Producto por Id y Nombre"
          value={searchTerm}
          onChange={handleSearchTermChange}
        />
        {filteredProducts.length > 0 && (
          <div className={style.productDropdown}>
            {filteredProducts.map((product) => (
              <div
                key={product.id}
                onClick={() => handleProductSelect(product)}
                className={style.productOption}
              >
                {`(${product.item}) ${product.descripcion}`}
              </div>
            ))}
          </div>
        )}
      </div>
      <input
        type="number"
        placeholder="Cantidad. . ."
        value={quantity}
        onChange={handleQuantityChange}
      />
      <input
        type="number"
        placeholder="Precio Venta + Iva"
        value={precioVenta}
        onChange={(e) => setPrecioVenta(e.target.value)}
      />
      <span className={style.spanAdvert}>INCLUIR IVA</span>
      <button
        type="button"
        onClick={handleAddRemisionPart}
        className={style.buttonAddProduct}
      >
        Agregar Producto
      </button>
    </div>
  );
};

export default DivRemisionParts;
