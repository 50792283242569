import { useSelector, useDispatch } from "react-redux";
import style from "./Clientes.module.css";
import Spinner from "../../components/Spinner/Spinner";
import { useState, useEffect } from "react";
import axios from "axios";
import { getAllClients } from "../../redux/actions";

const Clientes = () => {
  const [detalle, setDetalle] = useState(false);
  const [clienteDetalle, setClienteDetalle] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const clientesData = useSelector((state) => state.clients);
  const [paginatedClients, setPaginatedClients] = useState([]);
  const [filteredClientSearch, setFilteredClientSearch] = useState([]);
  const [itemsToDisplay, setItemsToDisplay] = useState([]);
  const [searchClient, setSearchClient] = useState("");
  const [searchByClient, setSearchByClient] = useState([]);
  const [isDropdownVisibleClients, setIsDropdownVisibleClients] =
    useState(false);
  const dispatch = useDispatch();

  const fetchClients = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.devlasbalineras.com/clientes/paginate/?page=${page}&limit=10`
      );
      if (response.data.success) {
        setPaginatedClients(response.data.data.data);
        setTotalPages(response.data.data.totalPages);
      } else {
        alert("No se pudieron cargar los clientes");
      }
    } catch (error) {
      alert(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (filteredClientSearch.length > 0) {
      setItemsToDisplay(filteredClientSearch);
    } else {
      setItemsToDisplay(paginatedClients);
    }
  }, [filteredClientSearch, paginatedClients]);

  const showDetail = (id) => {
    const cliente = clientesData.find((c) => c.id === id);

    if (cliente) {
      setClienteDetalle(cliente);
      setDetalle(true);
    } else {
      alert("no se encontró la remision");
    }
  };

  const handleActulizar = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://api.devlasbalineras.com/getClientesAPI"
      );

      if (response.data.success === true) {
        const newResponse = await axios.get(
          "https://api.devlasbalineras.com/updateClients"
        );
        if (newResponse.data.success === true) {
          dispatch(getAllClients());
          alert("Clientes Actualizados correctamente");
          setLoading(false);
        } else {
          alert("No se pudo actualizar correctamente");
        }
      } else {
        alert("No se actualizo correctamente");
      }
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    fetchClients(currentPage);
  }, [currentPage]);

  const handleFirstPage = () => setCurrentPage(1);
  const handlePrevPage = () =>
    setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
  const handleNextPage = () =>
    setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));
  const handleLastPage = () => setCurrentPage(totalPages);

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchClient(value);

    if (value.length > 0) {
      const filtered = clientesData.filter(
        (client) =>
          client.cliente.toLowerCase().includes(value.toLowerCase()) ||
          client.identificacion.toLowerCase().includes(value.toLowerCase())
      );
      setSearchByClient(filtered);
      setIsDropdownVisibleClients(true);
    } else {
      setIsDropdownVisibleClients(false);
      setFilteredClientSearch([])
    }
  };

  const selectClient = (cliente) => {
    setIsDropdownVisibleClients(false);
    setSearchClient(cliente.cliente);
    setFilteredClientSearch([cliente]);

  };

  return (
    <>
      {loading ? (
        <div className={style.divDeCarga}>
          <Spinner />
        </div>
      ) : (
        <div className={style.divContenedorClientes}>
          <div className={style.divHeaderClientes}>
            <h1>Clientes</h1>
            <div className={style.divInputSearch}>
              <input
                type="text"
                placeholder="Buscar Cliente..."
                value={searchClient}
                onChange={handleChange}
              />
              {isDropdownVisibleClients && searchByClient.length > 0 && (
                <div className={style.dropdown}>
                  {searchByClient.map((cliente) => (
                    <div
                      key={cliente.id}
                      className={style.dropdownItem}
                      onClick={() => selectClient(cliente)}
                    >
                      {cliente.cliente}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <button onClick={() => handleActulizar()}>
              Actulizar Clientes
            </button>
            <div className={style.pagination}>
              <button onClick={handleFirstPage}>Primer Página</button>
              <button onClick={handlePrevPage}>Anterior</button>
              <span>
                Página {currentPage} de {totalPages}
              </span>
              <button onClick={handleNextPage}>Siguiente</button>
              <button onClick={handleLastPage}>Última Página</button>
            </div>
          </div>
          <div className={style.divClientes}>
            <div className={style.referenciasDiv}>
              <h4>Cliente ID</h4>
              <h4>Nombre Comercial</h4>
              <h4>Identificación</h4>
              <h4>Tipo</h4>
              <h4>Dirección</h4>
              <h4>Ciudad</h4>
              <h4>Teléfono</h4>
              <h4>Acciones</h4>
            </div>
            <div className={style.clientes}>
              {itemsToDisplay ? (
                itemsToDisplay.map((cliente, index) => (
                  <div key={index} className={style.cliente}>
                    <p>{cliente.id}</p>
                    <p>{cliente.cliente}</p>
                    <p>{cliente.identificacion}</p>
                    <p>{cliente.tipoIdentificacion}</p>
                    <p>{cliente.direccion}</p>
                    <p>{cliente.ciudad}</p>
                    <p>{cliente.telefono}</p>
                    <div className={style.divAcciones}>
                      <div
                        onClick={() => showDetail(cliente.id)}
                        className={style.divAction}
                      >
                        <ion-icon
                          name="receipt-outline"
                          title="Detalle"
                        ></ion-icon>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <h1>No hay clientes</h1>
              )}
            </div>
          </div>
          <div
            className={
              detalle ? style.detalleClienteDiv : style.detalleClienteOffDiv
            }
          >
            {detalle ? (
              <div className={style.detalleCliente}>
                <h1>Detalle del Cliente</h1>
                <div
                  className={style.closeButton}
                  onClick={() => setDetalle(false)}
                >
                  <ion-icon name="close-outline"></ion-icon>
                </div>
                <div className={style.divContenedorDetalleCliente}>
                  {Object.keys(clienteDetalle)
                    .filter(
                      (key) =>
                        key !== "GrupoClientes" &&
                        key !== "SaldoCOP" &&
                        key !== "VendedorID" &&
                        key !== "NombreVendedor"
                    )
                    .map((key) => (
                      <div className={style.detalleClient} key={key}>
                        <h3>{key} :</h3>
                        <h4>{clienteDetalle[key]}</h4>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Clientes;
