import style from "./App.module.css";
import { useState, useEffect } from "react";
import CategorySelector from "./CategorySelector";
import SubcategorySelector from "./SubcategorySelector";
import VariantOptions from "./VariantOptions";
import axios from "axios";

function Referencias() {
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState(null);
  const [variantData, setVariantData] = useState([]);
  const [referenciaCreada, setReferenciaCreada] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [consultaResult, setConsultaResult] = useState("");
  const [similarItems, setSimilarItems] = useState([]);
  const [verReferenciasSimilares, setVerReferenciasSimilares] = useState(false);
  const [resetCategorySearch, setResetCategorySearch] = useState(false);

  const handleVariantChange = (
    variantId,
    optionId,
    variantName,
    optionValue
  ) => {
    setVariantData((prevData) => {
      const updatedData = prevData.filter(
        (item) => item.variantId !== variantId
      );
      return [
        ...updatedData,
        { variantId, optionId, variantName, optionValue },
      ];
    });
  };

  const handleVariantRemove = (variantId) => {
    setVariantData((prevData) =>
      prevData.filter((item) => item.variantId !== variantId)
    );
  };

  useEffect(() => {
    setVariantData([]);
    setReferenciaCreada("");
    setDescripcion("");
    setConsultaResult("");
    setSimilarItems([]);
  }, [selectedCategoryId, selectedSubcategoryId]);

  const toLowerCaseFirstLetter = (str) => {
    return str.charAt(0).toLowerCase() + str.slice(1);
  };

  const toLowerCaseFirstLetterKeys = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      acc[toLowerCaseFirstLetter(key)] = obj[key];
      return acc;
    }, {});
  };

  const clearAll = () => {
    setSelectedCategoryId(null);
    setSelectedSubcategoryId(null);
    setVariantData([]);
    setReferenciaCreada("");
    setDescripcion("");
    setConsultaResult("");
    setSimilarItems([]);
    setResetCategorySearch(true);
  };

  const transformVariantData = (variantData) => {
    return variantData.reduce((acc, { variantName, optionValue }) => {
      if (variantName.toLowerCase() === "medida") {
        acc[toLowerCaseFirstLetter(variantName)] = optionValue.valor;
      } else {
        acc[toLowerCaseFirstLetter(variantName)] = optionValue;
      }
      return acc;
    }, {});
  };

  const createReference = () => {
    if (variantData[0].variantName === "descripcion") {
      setReferenciaCreada(variantData[0].optionValue);
    } else {
      const variantDataTransformed = transformVariantData(variantData);
      const body = {
        categorieId: selectedCategoryId,
        subcategorieId: selectedSubcategoryId,
        ...variantDataTransformed,
      };

      const lowerCaseBody = toLowerCaseFirstLetterKeys(body);

      axios
        .post(
          "https://application.devlasbalineras.com/products/generate",
          lowerCaseBody
        )
        .then((response) => {
          setReferenciaCreada(response.data.referencia);
        })
        .catch((error) => {
          console.error("There was an error creating the reference!", error);
        });
    }
  };

  const consultarReferencia = () => {
    if (!referenciaCreada) return;

    axios
      .post("https://application.devlasbalineras.com/products/find", {
        item: referenciaCreada,
      })
      .then((response) => {
        const elements = response.data.elements;
        if (elements.length === 0) {
          setConsultaResult("No hay referencias similares!");
          setSimilarItems([]);
        } else {
          const sameReference = elements.some(
            (element) => element.item === referenciaCreada
          );
          if (sameReference) {
            setConsultaResult(
              "Se encontró la misma referencia, no se puede crear el producto !"
            );
            setSimilarItems([]);
          } else {
            setConsultaResult(`Hay ${elements.length} referencias similares`);
            setSimilarItems(elements);
          }
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the references!", error);
      });
  };

  const createProduct = () => {
    const data = {
      item: referenciaCreada,
      descripcion: descripcion,
      categorieId: selectedCategoryId,
      subcategorieId: selectedSubcategoryId,
    };

    const variantsData = variantData.map(
      ({ variantId, optionId, optionValue }) => ({
        variantId,
        optionId,
        optionValue:
          typeof optionValue === "object" && optionValue.valor
            ? String(optionValue.valor)
            : optionValue,
      })
    );

    let body;

    if (
      variantData.length === 0 ||
      (variantData[0].variantId === null && variantData[0].optionId === null)
    ) {
      body = { data };
    } else {
      body = { data, variantsData };
    }

    axios
      .post("https://application.devlasbalineras.com/products/create", body)
      .then((response) => {
        alert("Producto creado exitosamente");
        clearAll();
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleOnChangeDescription = (e) => {
    const value = e.target.value;
    if (value.length <= 150) {
      setDescripcion(value);
    }
  };

  const handleClearInputDescription = () => {
    setDescripcion("");
  };

  const handleCategoryReset = () => {
    setResetCategorySearch(false);
  };

  return (
    <div className={style.appDiv}>
      <div className={style.contenedorHeaderRef}>
        <h1>Generar Referencias</h1>
        <button onClick={clearAll} className={style.clearAllButton}>
          Borrar
        </button>
      </div>
      <div className={style.divCatAndSubCat}>
        <CategorySelector
          onCategorySelect={setSelectedCategoryId}
          resetSearch={resetCategorySearch}
          onResetComplete={handleCategoryReset}
        />
        {selectedCategoryId && (
          <SubcategorySelector
            categoryId={selectedCategoryId}
            onSubcategorySelect={setSelectedSubcategoryId}
          />
        )}
      </div>
      {selectedCategoryId && selectedSubcategoryId && (
        <VariantOptions
          categoryId={selectedCategoryId}
          subcategoryId={selectedSubcategoryId}
          onVariantChange={handleVariantChange}
          onVariantRemove={handleVariantRemove}
        />
      )}
      {selectedCategoryId && selectedSubcategoryId && (
        <div className={style.createDescriptionDiv}>
          <label>Descripción</label>
          <textarea
            placeholder="Descripción"
            value={descripcion}
            onChange={handleOnChangeDescription}
            className={style.textAreaDescr}
          />
          <button
            onClick={handleClearInputDescription}
            className={style.clearButtonDescription}
          >
            <ion-icon name="close-circle-outline"></ion-icon>
          </button>
          <p>{descripcion.length}/150</p>
        </div>
      )}
      <div className={style.divReferencias}>
        {selectedCategoryId && selectedSubcategoryId && (
          <button onClick={createReference} className={style.createButton}>
            Crear Referencia
          </button>
        )}
        {referenciaCreada !== "" && (
          <div className={style.divReferenciaCreated}>
            <h3>Referencia :</h3>
            <h3 className={style.h3Referencia}>{referenciaCreada}</h3>
          </div>
        )}
        <div className={style.createRefAndResult}>
          {referenciaCreada !== "" && (
            <button
              onClick={consultarReferencia}
              className={style.findReference}
            >
              Consultar Referencia
            </button>
          )}
          <div className={style.similarProdDiv}>
            {consultaResult && (
              <h3
                className={
                  consultaResult ===
                  "Se encontró la misma referencia, no se puede crear el producto !"
                    ? style.error
                    : style.access
                }
              >
                {consultaResult}
              </h3>
            )}
            {similarItems.length > 0 ? (
              <button
                onClick={() =>
                  setVerReferenciasSimilares(!verReferenciasSimilares)
                }
                className={style.buttonVerRef}
              >
                Ver
              </button>
            ) : (
              ""
            )}
          </div>
          {verReferenciasSimilares && (
            <div className={style.similarItems}>
              {similarItems.map((item) => (
                <p key={item.id}>{item.item}</p>
              ))}
            </div>
          )}
        </div>
        {consultaResult !==
          "Se encontró la misma referencia, no se puede crear el producto !" &&
        consultaResult !== "" ? (
          <button onClick={createProduct} className={style.buttonCrearProdRef}>
            Crear producto
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Referencias;
