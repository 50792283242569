import React from "react";
import { Link } from "react-router-dom"; // Import Link
import style from "./Divisor.module.css";
import imagenVer from "../../assets/imagen_deco_remi2.png";
import imagenCrear from "../../assets/imagen_deco_remi.png";

const Divisor = () => {
  return (
    <div className={style.divContenedorRemisiones}>
      <Link to={`/crearRemision`} className={style.divCrearRemision}>
        <h1>Crear Remision</h1>
        <div className={style.imagenDeco}>
          <img src={imagenCrear} alt="deco" />
        </div>
      </Link>
      <Link to={`/verRemisiones`} className={style.divVerRemisiones}>
        <h1>Ver Remisiones</h1>
        <div className={style.imagenDeco}>
          <img src={imagenVer} alt="deco" />
        </div>
      </Link>
    </div>
  );
};

export default Divisor;
