import "./App.css";

// VIEWS

import Login from "./views/Login/Login";
import NavBar from "./components/NavBar/NavBar";
import Remisiones from "./views/Remisiones/Remisiones";
import Divisor from "./components/Divisor/Divisor";
import VerRemisiones from "./views/verRemisiones/VerRemisiones";
import Home from "./views/Home/Home";
import Inventario from "./views/Inventario/Inventario";
import Clientes from "./views/Clientes/Clientes";
import CrearUsuarios from "./views/crearUsuario/CrearUsuarios";
import Soporte from "./components/Soporte/Soporte";
import PasswordView from "./views/password/Password";
import ChangePassword from "./views/password/ChangePassword";
import Referencias from "./views/Referencias/Referencias";

//ACTIONS

import { getAllProducts, getAllClients, getAllUsers } from "./redux/actions";

// UTILS

import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function App() {
  const userId = localStorage.getItem("idUser") || "default-user-id";
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProducts());
    dispatch(getAllClients());
    dispatch(getAllProducts());
    dispatch(getAllUsers());
  }, []);

  const ProtectedRoute = ({ children }) => {
    const userId = localStorage.getItem("idUser");

    if (!userId) {
      return <Navigate to="/" />;
    }

    return children;
  };

  return (
    <div className="App">
      {location.pathname !== "/" &&
        !location.pathname.startsWith("/home") &&
        !location.pathname.startsWith("/changePass") &&
        !location.pathname.startsWith("/reset/") && <NavBar />}
      {location.pathname !== "/" && <Soporte />}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/remisiones"
          element={
            <ProtectedRoute>
              <Divisor />
            </ProtectedRoute>
          }
        />
        <Route
          path="/crearRemision"
          element={
            <ProtectedRoute>
              <Remisiones />
            </ProtectedRoute>
          }
        />
        <Route
          path="/crearUsuarios"
          element={
            <ProtectedRoute>
              <CrearUsuarios />
            </ProtectedRoute>
          }
        />
        <Route
          path="/verRemisiones"
          element={
            <ProtectedRoute>
              <VerRemisiones />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Inventario"
          element={
            <ProtectedRoute>
              <Inventario />
            </ProtectedRoute>
          }
        />
        <Route
          path="/clientes"
          element={
            <ProtectedRoute>
              <Clientes />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Referencias"
          element={
            <ProtectedRoute>
              <Referencias />
            </ProtectedRoute>
          }
        />
        <Route path="/reset/:token" element={<ChangePassword />} />
        <Route path="/changePass" element={<PasswordView />} />
      </Routes>
    </div>
  );
}

export default App;
