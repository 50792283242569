import {
  GET_ALL_PRODUCTS,
  GET_ALL_CLIENTS,
  GET_ALL_REMISIONS,
  GET_ALL_USERS,
} from "./actionTypes";

const initialState = {
  inventario: [],
  clients: [],
  remisions: [],
  users: [],
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PRODUCTS: {
      return {
        ...state,
        inventario: action.payload,
      };
    }

    case GET_ALL_CLIENTS: {
      return {
        ...state,
        clients: action.payload,
      };
    }

    case GET_ALL_REMISIONS: {
      return {
        ...state,
        remisions: action.payload,
      };
    }

    case GET_ALL_USERS: {
      return {
        ...state,
        users: action.payload,
      };
    }

    default:
      return state;
  }
};

export default rootReducer;
