import style from "./Remisiones.module.css";
import { useState } from "react";
import { useSelector } from "react-redux";
import DivRemisionParts from "./RemisionesParts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getAllRemision } from "../../redux/actions";
import Spinner from "../../components/Spinner/Spinner";

const Remisiones = () => {
  const userId = localStorage.getItem("idUser") || "default-user-id";
  const [formData, setFormData] = useState({
    clientId: "",
    cliente: "",
    date: new Date(),
    observations: "",
    contact: "",
    contactPhone: "",
    remisionParts: [],
    total: 0,
    subtotal: 0,
    iva: 0,
    userId: userId,
    retencion: false,
    valorRetencion: null,
  });
  const [filteredClients, setFilteredClients] = useState([]);
  const [isDetailView, setIsDetailView] = useState(false);
  const [clientName, setClientName] = useState("");
  const [showSuccesMessage, setShowSuccesMessage] = useState(false);
  const [checkRetencion, setCheckRetencion] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  //
  const prods = useSelector((state) => state.inventario);
  const clients = useSelector((state) => state.clients);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddRemisionPart = (remisionPart) => {
    setCheckRetencion(false);
    setFormData((prevFormData) => ({
      ...prevFormData,
      remisionParts: [...prevFormData.remisionParts, remisionPart],
    }));
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filteredClients = clients.filter(
      (client) =>
        client.cliente.toLowerCase().includes(searchTerm) ||
        client.identificacion.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredClients(filteredClients);
    setClientName(e.target.value);
    if (!searchTerm) {
      setFilteredClients([]);
    }
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, date });
  };

  const handleClientClick = (selectedClient) => {
    setFormData({
      ...formData,
      clientId: selectedClient.id,
      cliente: selectedClient.cliente,
    });

    setClientName(selectedClient.cliente);
    setFilteredClients([]);
  };

  useEffect(() => {
    const totalConImpuesto = formData.remisionParts.reduce((acc, current) => {
      const precioVenta = parseFloat(current.precioVenta);
      const quantity = parseFloat(current.quantity);
      return acc + precioVenta * quantity;
    }, 0);

    setFormData((prevFormData) => ({
      ...prevFormData,
      total: totalConImpuesto.toFixed(2),
    }));
  }, [formData.remisionParts, checkRetencion]);

  useEffect(() => {
    const totalIncludingTax = formData.total;
    const calcSubTot = totalIncludingTax / 1.19;

    setFormData((prevFormData) => ({
      ...prevFormData,
      subtotal: calcSubTot.toFixed(2),
    }));
  }, [formData.remisionParts, formData.total]);

  useEffect(() => {
    if (checkRetencion) {
      const retencion = formData.subtotal * 0.025;
      const nuevoTotal = formData.total - retencion;
      setFormData((prevFormData) => ({
        ...prevFormData,
        valorRetencion: retencion.toFixed(2),
        retencion: checkRetencion,
        newTotal: nuevoTotal.toFixed(2),
      }));
    }
  }, [checkRetencion]);

  useEffect(() => {
    const totalIncludingTax = formData.total;
    const calcSubTot = totalIncludingTax / 1.19;

    setFormData((prevFormData) => ({
      ...prevFormData,
      iva: (totalIncludingTax - calcSubTot).toFixed(2),
    }));
  }, [formData.total]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setDisabledButton(true);
      const peticion = await axios.post(
        "https://api.devlasbalineras.com/remisions/create",
        formData
      );

      if (peticion.data.success === true) {
        setFormData({
          clientId: "",
          date: new Date(),
          observations: "",
          contact: "",
          contactPhone: "",
          remisionParts: [],
          subtotal: 0,
          total: 0,
          userId: userId,
        });
        setClientName("");
        setShowSuccesMessage(true);
        setLoading(false);
        setDisabledButton(false);
      } else {
        alert("no se pudo crear");
      }

      return peticion;
    } catch (error) {
      alert(error);
    }
  };

  const successRemision = (n) => {
    setShowSuccesMessage(false);
    dispatch(getAllRemision());

    if (n === "y") {
      navigate("/verRemisiones");
    }
  };

  const handleRemovePart = (indexToRemove) => {
    setCheckRetencion(false);
    const newRemisionParts = formData.remisionParts.filter(
      (_, index) => index !== indexToRemove
    );
    setFormData({ ...formData, remisionParts: newRemisionParts });
  };

  return (
    <div className={style.divContenedorRemisiones}>
      {loading ? (
        <div className={style.spinnerContainerDiv}>
          <Spinner />
        </div>
      ) : (
        ""
      )}
      <div className={style.crearRemision}>
        <form>
          <h1>Crear Remision</h1>

          <div className={style.searchableDropdown}>
            <input
              name="clientName"
              value={clientName}
              onChange={handleSearch}
              required
              placeholder="Buscar Cliente. . ."
            />
            {filteredClients.length > 0 && (
              <div className={style.clientDropdown}>
                {filteredClients.map((client) => (
                  <div
                    key={client.id}
                    onClick={() => handleClientClick(client)}
                    className={style.clientOption}
                  >
                    {client.cliente}
                  </div>
                ))}
              </div>
            )}
          </div>

          <DatePicker
            selected={formData.date}
            onChange={handleDateChange}
            dateFormat="dd-MM-yyyy"
            required
            disabled={isDetailView}
          />

          <textarea
            name="observations"
            value={formData.observations}
            onChange={handleChange}
            placeholder="Observaciones. . ."
            disabled={isDetailView}
          />

          <input
            name="contact"
            value={formData.contact}
            onChange={handleChange}
            placeholder="Contacto. . ."
            disabled={isDetailView}
          />

          <input
            name="contactPhone"
            value={formData.contactPhone}
            type="number"
            onChange={handleChange}
            placeholder="Telefono de contacto. . ."
            disabled={isDetailView}
          />

          {formData.remisionParts.length > 0 ? (
            <button
              type="button"
              onClick={() => setCheckRetencion(!checkRetencion)}
              className={
                checkRetencion ? style.retencionOn : style.retencionOff
              }
            >
              <h5>{checkRetencion ? "Con Retencion" : "Sin Retencion"}</h5>
            </button>
          ) : (
            ""
          )}

          <DivRemisionParts
            onAddRemisionPart={handleAddRemisionPart}
            productos={prods}
            isDetailView={isDetailView}
          />
        </form>
      </div>
      <div className={style.detalleRemision}>
        <h1>Detalle de la remision</h1>
        <div className={style.detalleClient}>
          <h3>Cliente</h3>
          <h4>{formData.cliente !== "" ? formData.cliente : ""}</h4>
        </div>
        <div className={style.detalleDate}>
          <h3>Fecha</h3>
          <DatePicker
            selected={formData.date}
            dateFormat="dd-MM-yyyy"
            disabled
          />
        </div>
        <div className={style.detalleObservaciones}>
          <h3>Observaciones</h3>
          <h4>{formData.observations !== "" ? formData.observations : ""}</h4>
        </div>
        <div className={style.detalleObservaciones}>
          <h3>Contacto</h3>
          <h4>{formData.contact !== "" ? formData.contact : ""}</h4>
        </div>
        <div className={style.detalleObservaciones}>
          <h3>Numero</h3>
          <h4>{formData.contactPhone !== "" ? formData.contactPhone : ""}</h4>
        </div>
        <div className={style.detalleProductos}>
          <h3>Productos</h3>
          <div className={style.contenedorProductosDetalle}>
            {formData.remisionParts.length > 0
              ? formData.remisionParts.map((p, index) => {
                  return (
                    <div className={style.productoDetalle} key={index}>
                      <h5>
                        {p.descripcion} ({p.item})
                      </h5>
                      <h5>{p.quantity} Unidades</h5>
                      <h5>
                        ${" "}
                        {p.precioVenta != null && p.precioVenta !== ""
                          ? Number(p.precioVenta).toLocaleString()
                          : ""}
                      </h5>
                      <div onClick={() => handleRemovePart(index)}>
                        <ion-icon name="trash-outline"></ion-icon>
                      </div>
                      {p.quantity > p.cantidadDisponible ? (
                        <ion-icon
                          name="alert-circle-outline"
                          title="Supera la cantidad disponible"
                        ></ion-icon>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
        <div className={style.detalleObservaciones}>
          {checkRetencion ? (
            <>
              <h3>Total con retencion :</h3>
              <h4>
                ${" "}
                {formData.newTotal !== undefined && formData.newTotal !== ""
                  ? Number(formData.newTotal).toLocaleString()
                  : ""}
              </h4>
            </>
          ) : (
            <>
              <h3>Total</h3>
              <h4>
                ${" "}
                {formData.total != null && formData.total !== ""
                  ? Number(formData.total).toLocaleString()
                  : ""}
              </h4>
            </>
          )}
        </div>
        <div className={style.divGuardarRemision} onClick={handleSubmit}>
          <button disabled={disabledButton}>Guardar Remision</button>
        </div>
      </div>
      {showSuccesMessage ? (
        <div className={style.divSuccesMessage}>
          <h1>Remision Creada Correctamente !</h1>
          <ion-icon name="checkmark-circle-outline"></ion-icon>
          <button onClick={() => successRemision("n")}>Cerrar</button>
          <button onClick={() => successRemision("y")}>
            Visitar Remisiones
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Remisiones;
