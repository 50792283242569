import React, { useState, useEffect } from "react";
import axios from "axios";
import style from "./App.module.css";

const CategorySelector = ({
  onCategorySelect,
  resetSearch,
  onResetComplete,
}) => {
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  useEffect(() => {
    axios
      .get("https://application.devlasbalineras.com/categories/all")
      .then((response) => {
        if (response.data.success) {
          setCategories(response.data.elements);
          setFilteredCategories(response.data.elements);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the categories!", error);
      });
  }, []);

  useEffect(() => {
    if (resetSearch) {
      setSearchTerm("");
      setFilteredCategories(categories);
      setIsDropdownVisible(false);
      onResetComplete();
    }
  }, [resetSearch, categories, onResetComplete]);

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    setIsDropdownVisible(true);

    if (term === "") {
      setFilteredCategories(categories);
    } else {
      const filtered = categories.filter((category) =>
        category.name.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredCategories(filtered);
    }
  };

  const handleSelect = (category) => {
    setSearchTerm(category.name);
    setFilteredCategories([category]);
    setIsDropdownVisible(false);
    onCategorySelect(category.id);
  };

  const handleInputClick = () => {
    setIsDropdownVisible(!isDropdownVisible);
    setFilteredCategories(categories);
  };

  const handleClearInput = () => {
    setSearchTerm("");
    setFilteredCategories(categories);
    setIsDropdownVisible(false);
    onCategorySelect(null);
  };

  return (
    <div className={style.containerInput}>
      <label>Categoria</label>
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        onClick={handleInputClick}
        placeholder="Buscar categorías..."
      />
      {isDropdownVisible && (
        <div className={style.dropdownDivCats}>
          {filteredCategories.map((category) => (
            <button key={category.id} onClick={() => handleSelect(category)}>
              {category.name}
            </button>
          ))}
        </div>
      )}
      <button onClick={handleClearInput} className={style.clearButtonCats}>
        <ion-icon name="close-circle-outline"></ion-icon>
      </button>
    </div>
  );
};

export default CategorySelector;
