import React, { useState, useEffect } from "react";
import axios from "axios";
import style from "./App.module.css";

const VariantOptions = ({
  categoryId,
  subcategoryId,
  onVariantChange,
  onVariantRemove,
}) => {
  const [variants, setVariants] = useState([]);
  const [options, setOptions] = useState({});
  const [searchTerms, setSearchTerms] = useState({});
  const [dropdownVisible, setDropdownVisible] = useState({});
  const [measureUnit, setMeasureUnit] = useState("MM");
  const [variantIdToAdd, setVariantIdToAdd] = useState(null);
  const [newOptionName, setNewOptionName] = useState("");
  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    axios
      .get("https://application.devlasbalineras.com/variants/all")
      .then((response) => {
        if (response.data.success) {
          setVariants(response.data.elements);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the variants!", error);
      });
  }, []);

  useEffect(() => {
    if (categoryId && subcategoryId && variants.length > 0) {
      const filteredVariantIds = getVariantsForCategory(categoryId);
      filteredVariantIds.forEach((variantId) => {
        const variant = variants.find((v) => v.id === variantId);
        if (variant) {
          fetchOptions(categoryId, subcategoryId, variant.id, variant.name);
        }
      });
    }
  }, [categoryId, subcategoryId, variants]);

  const fetchOptions = (categoryId, subcategoryId, variantId, variantName) => {
    axios
      .post(
        "https://application.devlasbalineras.com/options/filter?page=1&limit=3000",
        {
          categorieId: categoryId,
          subcategorieId: subcategoryId,
          variantId: variantId,
        }
      )
      .then((response) => {
        if (response.data.success && response.data.elements.totalItems > 0) {
          setOptions((prevOptions) => ({
            ...prevOptions,
            [variantId]: {
              data: response.data.elements.data,
              name: variantName,
            },
          }));
          setSearchTerms((prevSearchTerms) => ({
            ...prevSearchTerms,
            [variantId]: "",
          }));
          setDropdownVisible((prevDropdownVisible) => ({
            ...prevDropdownVisible,
            [variantId]: false,
          }));
        } else {
          setOptions((prevOptions) => ({
            ...prevOptions,
            [variantId]: { data: [], name: variantName },
          }));
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the options!", error);
      });
  };

  const handleSearch = (variantId, event) => {
    const term = event.target.value;
    setSearchTerms((prevSearchTerms) => ({
      ...prevSearchTerms,
      [variantId]: term,
    }));
    setDropdownVisible((prevDropdownVisible) => ({
      ...prevDropdownVisible,
      [variantId]: true,
    }));
  };

  const handleSelect = (variantId, optionId, optionName) => {
    setSearchTerms((prevSearchTerms) => ({
      ...prevSearchTerms,
      [variantId]: optionName,
    }));
    setDropdownVisible((prevDropdownVisible) => ({
      ...prevDropdownVisible,
      [variantId]: false,
    }));
    onVariantChange(variantId, optionId, options[variantId].name, optionName);
  };

  const handleInputChange = (variantId, value) => {
    const variantName = options[variantId].name;
    const inputOption = options[variantId].data.find(
      (option) => option.name.toLowerCase() === "input"
    );
    const optionId = inputOption ? inputOption.id : null;

    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [variantId]: value,
    }));

    if (variantName.toLowerCase() === "medida") {
      onVariantChange(variantId, optionId, variantName, value);
    } else if (variantName.toLowerCase() === "dimension") {
      onVariantChange(variantId, optionId, variantName, parseFloat(value));
    } else {
      onVariantChange(variantId, optionId, variantName, value);
    }
  };

  const handleUnitToggle = () => {
    setMeasureUnit((prevUnit) => (prevUnit === "MM" ? "INCH" : "MM"));
  };

  const handleClearInput = (variantId) => {
    setSearchTerms((prevSearchTerms) => ({
      ...prevSearchTerms,
      [variantId]: "",
    }));
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [variantId]: "",
    }));
    onVariantRemove(variantId);
  };

  const crearOpcion = () => {
    axios
      .post("https://application.devlasbalineras.com/options/create", {
        name: newOptionName,
        variantId: variantIdToAdd,
        categorieId: categoryId,
        subcategorieId: subcategoryId,
      })
      .then((response) => {
        alert("Opción creada exitosamente");
        setVariantIdToAdd(null);
        setNewOptionName("");
        fetchOptions(
          categoryId,
          subcategoryId,
          variantIdToAdd,
          options[variantIdToAdd].name
        );
      })
      .catch((error) => {
        console.error("There was an error creating the option!", error);
      });
  };

  const getVariantsForCategory = (categoryId) => {
    switch (categoryId) {
      case 1: // Rodamientos
        return [1, 2, 3, 4, 5, 6, 7, 8];
      case 2: // Retenedores
        return [2, 8, 9, 10, 11];
      case 3: // Chumaceras
        return [1, 2, 4, 6, 7, 8];
      default:
        return [];
    }
  };

  const filteredVariants = variants.filter((variant) =>
    getVariantsForCategory(categoryId).includes(variant.id)
  );

  if (categoryId === 4 || (categoryId === 3 && subcategoryId === 11)) {
    return (
      <div className={style.singleInputContainer}>
        <label>Descripción</label>
        <input
          type="text"
          placeholder="Ingrese descripción del producto"
          onChange={(e) =>
            onVariantChange(null, null, "descripcion", e.target.value)
          }
        />
      </div>
    );
  }

  return (
    <div className={style.containerVariants}>
      {filteredVariants.map((variant) => (
        <div key={variant.id} className={style.variantContainer}>
          <label>{variant.name}</label>
          {options[variant.id] &&
          options[variant.id].data.some((option) => option.name === "Input") ? (
            <div className={style.divContenedorInputs}>
              <input
                type="text"
                placeholder="Ingrese valor"
                value={inputValues[variant.id] || ""}
                onChange={(e) => handleInputChange(variant.id, e.target.value)}
              />
              {variant.name.toLowerCase() === "medida" && (
                <button onClick={handleUnitToggle} className={style.unitButton}>
                  {measureUnit}
                </button>
              )}
              <button
                onClick={() => handleClearInput(variant.id)}
                className={style.clearButton}
              >
                <ion-icon name="close-circle-outline"></ion-icon>
              </button>
            </div>
          ) : (
            <div className={style.containerInput}>
              <input
                type="text"
                value={searchTerms[variant.id] || ""}
                onChange={(e) => handleSearch(variant.id, e)}
                onClick={() =>
                  setDropdownVisible((prevDropdownVisible) => ({
                    ...prevDropdownVisible,
                    [variant.id]: !prevDropdownVisible[variant.id],
                  }))
                }
                placeholder={`Buscar ${variant.name}...`}
              />
              {dropdownVisible[variant.id] && (
                <div className={style.dropdownDiv}>
                  {options[variant.id]?.data
                    ?.filter((option) =>
                      option.name
                        .toLowerCase()
                        .includes(searchTerms[variant.id].toLowerCase())
                    )
                    .map((option) => (
                      <div
                        key={option.id}
                        onClick={() =>
                          handleSelect(variant.id, option.id, option.name)
                        }
                      >
                        {option.name}
                      </div>
                    ))}
                  {options[variant.id]?.data?.filter((option) =>
                    option.name
                      .toLowerCase()
                      .includes(searchTerms[variant.id].toLowerCase())
                  ).length === 0 && (
                    <div
                      onClick={() => setVariantIdToAdd(variant.id)}
                      style={{
                        cursor: "pointer",
                        padding: "5px",
                        border: "1px solid #ccc",
                        marginTop: "2px",
                        backgroundColor: "white",
                      }}
                    >
                      Crear opción
                    </div>
                  )}
                </div>
              )}
              <button
                onClick={() => handleClearInput(variant.id)}
                className={style.clearButton}
              >
                <ion-icon name="close-circle-outline"></ion-icon>
              </button>
            </div>
          )}
        </div>
      ))}
      {variantIdToAdd != null ? (
        <div className={style.divContCrearOpcion}>
          <i onClick={() => setVariantIdToAdd(null)}>X</i>
          <h3>Creador de opciones</h3>
          <div>
            <label>Nombre de la opción</label>
            <input
              type="text"
              placeholder="Nombre"
              value={newOptionName}
              onChange={(e) => setNewOptionName(e.target.value)}
            />
          </div>
          <button onClick={crearOpcion}>Crear Opción</button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default VariantOptions;
