import style from "./Inventario.module.css";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import axios from "axios";
import Spinner from "../../components/Spinner/Spinner";

const Inventario = () => {
  const prods = useSelector((state) => state.inventario);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [paginatedInv, setPaginatedInv] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [isDropdownVisibleClients, setIsDropdownVisibleClients] =
    useState(false);
  const [itemsToDisplay, setItemsToDisplay] = useState([]);

  useEffect(() => {
    if(selectedItem.length > 0){
      setItemsToDisplay(selectedItem)
    }else{
      setItemsToDisplay(paginatedInv)
    }
  }, [selectedItem, paginatedInv])

  const handleActulizar = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://api.devlasbalineras.com/getItemsAPI"
      );

      if (response.data.success === true) {
        const newResponse = await axios.get(
          "https://api.devlasbalineras.com/updateItems"
        );
        if (newResponse.data.success === true) {
          alert("Inventario Actualizado correctamente");
          setLoading(false);
        } else {
          alert("No se pudo actualizar correctamente");
        }
      } else {
        alert("No se actualizo correctamente");
      }
    } catch (error) {
      alert(error);
    }
  };

  const fetchClients = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.devlasbalineras.com/inventario/paginate/?page=${page}&limit=10`
      );
      if (response.data.success) {
        setPaginatedInv(response.data.data.data);
        setTotalPages(response.data.data.totalPages);
      } else {
        alert("No se pudieron cargar los productos");
      }
    } catch (error) {
      alert(error);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchItem(value);

    if (value.length > 4) {
      const filtered = prods.filter(
        (prod) =>
          prod.descripcion.toLowerCase().includes(value.toLowerCase()) ||
          prod.item.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredItems(filtered);
      setIsDropdownVisibleClients(true);
    } else {
      setIsDropdownVisibleClients(false);
      setFilteredItems([]);
      setSelectedItem([]);
    }
  };

  const selectClient = (prod) => {
    setIsDropdownVisibleClients(false);
    setSearchItem(prod.descripcion);
    setSelectedItem([prod]);
  };

  useEffect(() => {
    fetchClients(currentPage);
  }, [currentPage]);

  const handleFirstPage = () => setCurrentPage(1);
  const handlePrevPage = () =>
    setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
  const handleNextPage = () =>
    setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));
  const handleLastPage = () => setCurrentPage(totalPages);

  return (
    <>
      {loading ? (
        <div className={style.divDeCarga}>
          <Spinner />
        </div>
      ) : (
        <div className={style.divContenedorInventario}>
          <div className={style.divHeaderInv}>
            <h1>Inventario</h1>
            <div className={style.divInputSearch}>
              <input
                type="text"
                placeholder="Buscar producto..."
                value={searchItem}
                onChange={handleChange}
              />
              {isDropdownVisibleClients && filteredItems.length > 0 && (
                <div className={style.dropdown}>
                  {filteredItems.map((prod) => (
                    <div
                      key={prod.id}
                      className={style.dropdownItem}
                      onClick={() => selectClient(prod)}
                    >
                      <h4>({prod.item}) {prod.descripcion}</h4>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <button onClick={() => handleActulizar()}>
              Actulizar Inventario
            </button>
            <div className={style.pagination}>
              <button onClick={handleFirstPage}>Primer Página</button>
              <button onClick={handlePrevPage}>Anterior</button>
              <span>
                Página {currentPage} de {totalPages}
              </span>
              <button onClick={handleNextPage}>Siguiente</button>
              <button onClick={handleLastPage}>Última Página</button>
            </div>
          </div>
          <div className={style.divInventario}>
            <div className={style.referenciasDiv}>
              <h4>Item</h4>
              <h4>Descripción</h4>
              <h4>Precio de Venta</h4>
              <h4>Cantidad</h4>
              <h4>Estado</h4>
            </div>
            {prods.length < 1 ? (
              <h1>Cargando...</h1>
            ) : (
              <div className={style.Inventario}>
                {itemsToDisplay.map((inventario, index) => (
                  <div key={index} className={style.inventario}>
                    <p>{inventario.item}</p>
                    <p>{inventario.descripcion}</p>
                    <p>$ {inventario.precioVenta}</p>
                    <p>{inventario.cantidadDisponible}</p>
                    <p>{inventario.estado}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Inventario;
