import { GET_ALL_USERS } from "../actionTypes";
import axios from "axios";

export const getAllUsersAction = () => {
  return async function (dispatch) {
    try {
      const apiData = await axios.get("https://api.devlasbalineras.com/users");
      const data = apiData.data.users;

      dispatch({ type: GET_ALL_USERS, payload: data });
    } catch (error) {
      return error.message;
    }
  };
};
